import "../../assets/scss/app.scss";
import Swiper from 'swiper/bundle';



// fadeIn
function fadeIn(elm, ms) {
  elm.style.opacity = 0; // 透過度０
  elm.style.display = "block";
  elm.style.transition = "opacity " + ms + "ms";
  setTimeout(function () {
    elm.style.opacity = 1;
  }, 1); // 0.001秒後に transition開始(透過度１にする）
}
// fadeOut
function fadeOut(elm, ms) {
  elm.style.opacity = 1; // 透過度1
  elm.style.transition = "opacity " + ms + "ms";
  setTimeout(function () {
    elm.style.opacity = 0;
  }, 5); // 0.001秒後に transition開始（透過度0にする）
  setTimeout(function () {
    elm.style.display = "none";
  }, ms + 10); // 1.01秒後に完全に消す。
}



class Main {
  constructor() {
    const option = {
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        reverseDirection: false
      },
      effect: 'fade',
      speed: 600,
    }

    const fvSwiper = new Swiper('.fvSwiper', option);


    const menu = document.querySelector("#menu");
    const menuBtn = document.querySelector("#menuBtn");
    let menuFlg = false;
    menuBtn.addEventListener("click",()=>{
      if(menuFlg === false){
        menuFlg = true;
        menuBtn.classList.add("act");
        fadeIn(menu,500);
      }else{
        menuFlg = false;
        menuBtn.classList.remove("act");
        fadeOut(menu,500);
        
      }
    })

    const sagd9 = document.querySelector(".sagd9");
    if(sagd9){
      const submitBtn = document.querySelector(".facilityBtn");
      const areaBox = document.querySelector('#feas_0_0');
      const kindsBox = document.querySelector('#feas_0_1');

      submitBtn.addEventListener("click",()=>{
        console.log(areaBox.options[areaBox.selectedIndex].value);
        console.log(kindsBox.options[kindsBox.selectedIndex].value);
        window.location.href = `/?area=${areaBox.options[areaBox.selectedIndex].value}&kinds=${kindsBox.options[kindsBox.selectedIndex].value}`;
      })
  

    // console.log( location.search)
    const serchParams = new URLSearchParams(window.location.search);
    if(serchParams.has("area")){
      console.log(serchParams.get("area"))
      Array.prototype.forEach.call(areaBox.options, function(option){
        if(option.value === serchParams.get("area")){
          // console.log(option.value)
          option.selected = true
        }
      })
    }
    if(serchParams.has("kinds")){
      console.log(serchParams.get("kinds"))
      Array.prototype.forEach.call(kindsBox.options, function(option){
        if(option.value === serchParams.get("kinds")){
          // console.log(option.value)
          option.selected = true
        }
      })
    }


  }
  }
}

window.addEventListener("DOMContentLoaded", () => {
  new Main();
});